import React from 'react';
import BlogPost from '../components/BlogPost/blogPost';
import NoPost from '../components/BlogPost/noPost';
import { useGetBlogPostQuery } from '../reactWrapper/Redux/apiSlice'


const MyPost =()=>{
    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetBlogPostQuery()

    if(isLoading){
        return(
            <>
                <span> Loading...</span>
            </>
        )
    }
    if(data.length === 0){
        return(
            <>
                <NoPost />
            </>
        )
    } else if (isSuccess) {
        return(
            <>
                <BlogPost blogItems = {data} />
            </>
        )
    } else if (isError) {
        <div>{error.toString()}</div>
    }
}

export default MyPost;