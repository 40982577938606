import React from 'react';
import Divider from '../../components/Divider/Divider';
import ClientDetails from '../../components/ProjectInfo/ClientDetails';
import JobDetails from '../../components/ProjectInfo/JobDetails';
import SkillsInfo from '../../components/ProjectInfo/SkillsInfo';
import ProgressDetails from '../../components/ProjectInfo/ProgressDetails';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useGetAHireRequestByIdQuery } from '../../reactWrapper/Redux/apiSlice';

const CompanyDetails = () => {
  const {id} = useParams();
  const {data}= useGetAHireRequestByIdQuery(id)
  return (
    <div>
      <div>
        {/* Client Details */}
        <div>
          <ClientDetails clientsdetails={data} />
        </div>
        <Divider className={'mt-7 mb-12'} />
        {/* Skills Infomation*/}
        <div>
          <SkillsInfo skills= {data} />
        </div>
        <Divider className={'mb-7 mt-12'} />
        {/* Job Details */}
        <div>
          <JobDetails jobdetails = {data} />
        </div>
        <Divider className={'mb-7 mt-12'} />
        {/* Progress Details */}
        <div>
          <ProgressDetails />
        </div>
      </div>
    </div>
  );
};

export default CompanyDetails;
