import React, { ReactNode } from "react";
import { selectUserState } from "../../reactWrapper/Redux/auth/authSlice";
import { useTypedSelector } from "../../reactWrapper/Redux/utilities";

type Props = {
  children: ReactNode;
};

export const Header = ({ children }: Props) => {
  const userState = useTypedSelector(selectUserState);

  return (
    <>
      <main className="main flex flex-col flex-grow -ml-64 md:ml-0 transition-all duration-150 ease-in">
        <header className="header bg-white shadow py-4 px-4">
          <div className="header-content flex items-center flex-row">
            <form action="#">
              <div className="hidden md:flex relative">
                <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg>
                </div>

                <input
                  id="search"
                  type="text"
                  name="search"
                  className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-300 w-full h-10 focus:outline-none focus:border-indigo-400"
                  placeholder="Search..."
                />
              </div>
              <div className="flex md:hidden">
                <a
                  href="/"
                  className="flex items-center justify-center h-10 w-10 border-transparent"
                >
                  <svg
                    className="h-6 w-6 text-gray-500"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg>
                </a>
              </div>
            </form>
            <div className="flex ml-auto w-fit">
              <a href="/" className="flex flex-row items-center">
                <img
                  src={process.env.REACT_APP_DEFAULT_PHOTO ?? ""}
                  alt=""
                  className="h-10 w-10 bg-gray-200 border rounded-full"
                />
                <span className="flex flex-col ml-2">
                  <span className="max-w-[50%] w-fit capitalize font-semibold tracking-wide leading-none whitespace-pre">
                    {userState.user?.firstName} {userState.user?.lastName}
                  </span>
                  <span className="capitalize w-fit text-gray-500 text-xs leading-none mt-1">
                    {userState.user?.role}
                  </span>
                </span>
              </a>
            </div>
          </div>
        </header>
        {/* Added the maximum width and center to remove later */}
        <div className="bg-[#F3F4F6] p-20">{children}</div>
      </main>
    </>
  );
};
