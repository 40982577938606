import { TOKEN } from "../../helper/constant";
import { logout } from "../Redux/auth/authSlice";
import { useAppDispatch } from "../Redux/utilities";

const useHandleLogout = () => {
  const dispatch = useAppDispatch();

  const handleLogout = (callback?: () => void) => {
    dispatch(logout());
    localStorage.removeItem(TOKEN);
    callback && callback();
    window.location.href = process.env.REACT_APP_BASE_WEB_URL ?? '';
  };

  return {
    handleLogout,
  };
};

export default useHandleLogout;
