import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { Link, } from 'react-router-dom/cjs/react-router-dom.min';
import Status from '../Status/Status';
import Table from './Table';
import Pagination from '../Pagination/Pagination';
import {useGetAllMatchedTalentPerJobReqQuery } from '../../reactWrapper/Redux/apiSlice';
//import { MatchedTalentsList } from '../../mockData';

const MatchedTalentsTable = ({matched}) => {
  const siblingCount = 1;
  const pageColumn = 1;
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const {data} = useGetAllMatchedTalentPerJobReqQuery(matched, page)

 

  const handlePageChange = useCallback(
    (page) => {
      setPage(page - 1);
    },
    [page]
  );
 
  const tableHeader = useMemo(
    () => [
      {
        title: 'Talents',
        key: 'talent',
      },
      {
        title: 'Stack',
        key: 'stack',
      },
      {
        title: 'Date',
        key: 'date',
      },
      {
        title: 'Status',
        key: 'status',
      },
      {
        title: '',
        key: 'details',
      },
    ],
    []
  );


  const modifiedMatchedTalentList  = useMemo(()=>{
    return data?.data?.map((each)=>[

    <div className='flex gap-[18px] items-center'>
      <div className='w-11 h-11'>
        <img
          src={each?.matchedTalentUser.photo ?? ""}
          alt={each?.matchedTalentUser.userName ?? ""}
        />
      </div>
      <p>{each?.matchedTalentUser.firstName + " " + each?.matchedTalentUser.lastName}</p>
    </div>,
    each.matchedTalentUser.primaryStack ?? "",
      // new Date(each?.created_at ?? Date.now()), "EEE, MMM d",
      new Date(each?.created_at).toLocaleDateString(),
    <Status label={!!each?.is_interested ? "Interested" : "Not-Interested"}/>,
    <Link
      className='text-sm inline-block rounded-lg border border-[#8D8989] py-[1.56px] px-[11.7px] text-[#575555] '
      to={`/matched-talents/cbt-profile/${each?.id}`}
    >
      Details
    </Link>
  
    ])
  })

  useEffect(() => {
    setTotalCount(data?.total ?? 0);
  }, [data]);

  return (
    <div>
      <Table
        header={tableHeader}
        label='List of matched Talents'
        column={modifiedMatchedTalentList}
      />

       <section className="w-full py-5">
        <Pagination
          rangeStart={
            totalCount !== 0
              ? (page || 0) * ((pageSize || 0) * pageColumn) + 1
              : 0
          }
          totalCount={totalCount}
          currentPage={(page || 0) + 1}
          pageSize={pageSize}
          pageColumn={pageColumn}
          siblingCount={siblingCount}
          onPageChange={handlePageChange}
          className="pt-9"
        />
        
        
        
      </section>
    </div>
  );
};

export default MatchedTalentsTable;
