import React, { useMemo } from 'react';
import Table from './Table';
import { PaymentRequestsList } from '../../mockData';

const PaymentRequestsTable = () => {
  const tableHeader = useMemo(
    () => [
      {
        title: 'Talents',
        key: 'talent',
      },
      {
        title: "Talent's Id",
        key: 'id',
      },
      {
        title: 'Account',
        key: 'account',
      },
      {
        title: 'Date',
        key: 'date',
      },
      {
        title: 'Amount ($)',
        key: 'amount',
      },
      {
        title: '',
        key: 'details',
      },
    ],
    []
  );

  return (
    <div>
      <Table header={tableHeader} label='' column={PaymentRequestsList} />
    </div>
  );
};

export default PaymentRequestsTable;
