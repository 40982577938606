import { BriefcaseIcon, ChatAlt2Icon, ClipboardCheckIcon, CollectionIcon, UsersIcon } from "@heroicons/react/solid";
import React from "react"; 

 import { useGetPlatformKpiQuery} from "../reactWrapper/Redux/apiSlice";

const HomeAdminDashboard = () => { 
  const {data: platformKpi, isLoading: isPlatformKpiLoading } = useGetPlatformKpiQuery()
//   const { data: categories, isLoading: isCategoriesLoading, error: categoriesError } = useGetCategoryQuery();
//   const { data: authors, isLoading: isAuthorsLoading, error: authorsError } = useGetAuthorQuery();
//   const { data: blogPosts, isLoading: isBlogPostsLoading, error: blogPostsError } = useGetBlogPostQuery();

//   if (isCategoriesLoading || isAuthorsLoading || isBlogPostsLoading) {
//     return <div>Loading...</div>;
//   }

//   if (categoriesError || authorsError || blogPostsError) {
//     return <div>Error: {categoriesError || authorsError || blogPostsError}</div>;
//   }

// 
// 
//
// 
// 
//
//
//

  return (
  <div>



    {
      isPlatformKpiLoading ? (
        <div>Loading..</div>
      ):(
    
    <div className="container">
      <div className="grid grid-cols-5 gap-4">
        <div className=" relative col-span-1 bg-white rounded-lg shadow-md h-[200px] p-4">
          <h2 className="text-l font-bold mb-2 text-gray-400">Users</h2>
          <span className="absolute top-3 inline-block w-7 h-7 p-1 rounded-full bg-orange-200 right-3"><UsersIcon className="h-5 w-5 text-white" /></span>
          <p className="text-center p-6 text-7xl font-bold text-black-400">{platformKpi?.allUser}</p>
        </div>
        <div className=" relative col-span-1 bg-white rounded-lg shadow-md p-4">
          <h2 className="text-l font-bold mb-2 text-gray-400">Talents</h2>
          <span className="absolute top-3 inline-block w-7 h-7 p-1 rounded-full bg-orange-600  right-3"><BriefcaseIcon className="h-5 w-5 text-white" /> </span>
                   
          <p className="text-center p-6 text-7xl font-bold text-black-500">{platformKpi?.allTalent}</p>
        </div>
        <div className="relative col-span-1 bg-white rounded-lg shadow-md p-4">
          <h2 className="text-l font-bold mb-2 text-gray-400">Interviewed</h2>
          <span className="absolute top-3 inline-block w-7 h-7 p-1 rounded-full bg-red-200  right-3"><CollectionIcon className="h-5 w-5 float-right text-white" /></span>
          <p className="text-center p-6 text-7xl font-bold text-black-500"> {platformKpi?.allInterviewed}</p>
        </div>

        <div className="relative col-span-1 bg-white rounded-lg shadow-md h-[200px] p-4">
          <h2 className="text-l font-bold mb-2 text-gray-400">Matched Gig </h2>
          <span className="absolute top-3 inline-block w-7 h-7 p-1 rounded-full bg-orange-700 right-3"><ClipboardCheckIcon className="h-5 w-5 text-white" /></span> 
          <p className=" text-center p-6 text-7xl font-bold text-black-500">{platformKpi?.allGigMatchRequest}</p>
        </div>
        <div className=" relative col-span-1 bg-white rounded-lg shadow-md  h-[200px] p-4">
          <h2 className="text-l font-bold mb-2 text-gray-400">Cbt Tested</h2>
          <span className="absolute top-3 inline-block w-7 h-7 p-1 rounded-full bg-red-400 right-3"><ChatAlt2Icon className="h-5 w-5 text-white" /></span> 
          <p className=" text-center p-6 text-7xl font-bold text-black-500 centered">{platformKpi?.allHardSkillTested}</p>
        </div>
      </div>


      
      <div className="grid p-0 w-[100%] mt-4">
        

        <div className="col-span-1 flex flex-col w-[100%] mt-0 bg-white shadow-md rounded-lg p-4">
            <div className="font-bold text-l mb-2 text-gray-400">Talents Level</div>
            <div className="flex flex-col space-y-2">
                <div className="flex justify-between items-center border-b border-gray-300 py-2">
                    <span>Junior Level</span>
                   <span> {platformKpi?.allJuniorLevelTalents} </span>
                </div>
                <div className="flex justify-between items-center border-b border-gray-300 py-2">
                    <span>Intermediate Level</span>
                    <span> {platformKpi?.allMidLevelTalents}</span>
                </div>
                <div className="flex justify-between items-center border-b border-gray-300 py-2">
                    <span>Senior Level</span>
                    <span> {platformKpi?.allSeniorLevelTalents}</span>
                </div>
                
            </div>
        </div>


      </div>
     

    </div>
    )}
  </div>
 )}



export default HomeAdminDashboard;


