import { AiFillCheckCircle, AiOutlineClose } from 'react-icons/ai';
import ControlledModal from './ControlledModal';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const SuccessfulEntryModal = ({ open, closeModal }) => {
  const history = useHistory();
  const contentStyle = {
    maxHeight: '566px',
    borderRadius: '25px',
    backgroundColor: 'white',
    width: 'fit-content',
    boxShadow: 'none',
    overflow: 'hidden',
    margin: 'auto',
    maxWidth: '530px',
  };

  const closeModalHandler = () => {
    closeModal();
  };
  return (
    <ControlledModal
      open={open}
      closeModal={closeModalHandler}
      contentStyle={contentStyle}
    >
      <div className='relative text-center'>
        <div
          className='absolute right-10 top-12'
          onClick={() => history.push('/')}
        >
          <AiOutlineClose size={36} onClick={closeModal} fill='black' />
        </div>
        <div className='mx-auto p-6 lg:py-36 text-center'>
          <div className='mb-[52px]'>
            <AiFillCheckCircle size={96} fill='#2ACF4F' className='mx-auto' />
          </div>
          <div>
            <p className='text-4xl text-primary mb-4 font-semibold w-3/5 mx-auto'>
              Result Entry Successful
            </p>
          </div>
        </div>
      </div>
    </ControlledModal>
  );
};

export default SuccessfulEntryModal;
