import React from 'react';

const JobDetails = ({jobdetails}) => {
  return (
    <div>
      <h2 className='text-xl lg:text-2xl font-semibold leading-8 mb-3.5'>
        Job details
      </h2>
      <div className='gap-[30px] grid xl:grid-cols-4 md:grid-cols-3 grid-cols-2 '>
        <div className='flex items-center gap-9'>
          <h3 className='text-xl font-semibold'>Apply link:</h3>
          <p className='lg:text-lg text-[#575555]'>{jobdetails?.applyUrl}</p>
        </div>
        <div className='flex items-center gap-9'>
          <h3 className='text-xl font-semibold'>Posted date:</h3>
          <p className='lg:text-lg text-[#575555]'>{jobdetails?.postedData}</p>
        </div>
        <div className='flex items-center gap-9'>
          <h3 className='text-xl font-semibold'>Salary range:</h3>
          <p className='lg:text-lg text-[#575555]'>{jobdetails?.salary_range}</p>
        </div>
        <div className='flex items-center gap-9'>
          <h3 className='text-xl font-semibold'>Work type:</h3>
          <p className='lg:text-lg text-[#575555]'>{jobdetails?.workType}</p>
        </div>
        <div className='flex items-center gap-9'>
          <h3 className='text-xl font-semibold'>Job location:</h3>
          <p className='lg:text-lg text-[#575555]'>{jobdetails?.jobLocation1}</p>
        </div>
        
        <div className='flex items-center gap-9'>
          <h3 className='text-xl font-semibold'>Experience</h3>
          <p className='lg:text-lg text-[#575555]'>{jobdetails?.level}</p>
        </div>
       
      </div>
      
      <div className='flex items-center gap-9'>
          <h3 className='text-xl font-semibold'>Job Description:</h3>
          <p className='lg:text-lg text-[#575555]'>{jobdetails?.jobDescription}</p>
        </div>
    </div>
  );
};

export default JobDetails;
//Lets job description and applyUrl be a button since it all url.
