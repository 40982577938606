import React, { useEffect } from 'react';
import { Form, Formik } from 'formik';

import IconInput from '../../components/Inputs/IconInput';
import Button from '../../components/Button';
import {
  SearchTalentSchema,
  initialSearchValue,
} from '../../schema/score.schema';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useVerifySoftSkillEmailMutation } from '../../reactWrapper/Redux/apiSlice';
import { toast } from 'react-toastify';

let history
const SearchTalent = () => {
   const[verifySoftSkillEmail, {isSuccess, data, error}] = useVerifySoftSkillEmailMutation({ fixedCacheKey: 'shared-update-post'})
   history = useHistory();
   console.log('verify', data)

   const handleSubmit = (values, {resetForm}) => {

   const email = new FormData()
         email.append('email', values.email)
         verifySoftSkillEmail(email)
         resetForm()

  }

  useEffect(()=>{
    if(isSuccess){
      history.push('/search-talent/result');
      }
      else if(error){
      toast.error(error.data.message)
      }
  })
 

  return (
    <div className=''>
      <div>
        <h2 className='text-xl font-medium text-[#001935]'>Talent HR Rating</h2>
        <Formik
          onSubmit={handleSubmit}
          validationSchema={SearchTalentSchema}
          enableReinitialize
          initialValues={initialSearchValue}
        >
          {({
            values,
            setFieldTouched,
            isValid,
            setFieldValue,
            errors,
            handleSubmit,
            ...formik
          }) => (
            <Form className='inline-flex flex-col gap-9'>
              <IconInput
                placeholder={'search talent with email'}
                name='email'
                id='email'
                type='email'
                value={values?.email}
                isValid={values?.email && !errors?.email}
                setFieldTouched={setFieldTouched}
                handleSubmit={handleSubmit}
              />
              <Button className={'bg-[#001935] text-white'} type='submit'>
                Search
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default SearchTalent;
