import React from 'react';
import PageIntro from '../../components/PageIntro/PageIntro';
import PaymentRequestsTable from '../../components/Table/PaymentRequestsTable';
import Pagination from '../../components/Pagination/Pagination';


const TalentPayment = () => {
  return (
    <div>
      <PageIntro
        title={'All payment requests'}
        description={
          'Lorem ipsum dolor sit amet consectetur. Pharetra varius tristique mauris auctor mauris pellentesque odio accumsan eget. Nec at ut malesuada elit elementum non nunc. Tellus vel purus consectetur elit tempus molestie '
        }
      />
      <div className='mt-12'>
        <PaymentRequestsTable />
        <div>
          <Pagination/>
        </div>
      </div>
    </div>
  );
};

export default TalentPayment;
