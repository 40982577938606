import React from 'react';

const Table = ({ header, column, label }) => {
  return (
    <div className='rounded-[15px] border border-solid border-[rgba(141_137_137/0.50)] border-opacity-50 p-4 lg:py-8 lg:px-9 bg-white'>
      {/* HEADING */}
      {label && (
        <h2 className={`text-[#001935] text-2xl font-bold text-left mb-12`}>
          {label}
        </h2>
      )}

      <div className='flex flex-col'>
        <div className='overflow-x-auto'>
          <div className='inline-block min-w-full'>
            <div className='overflow-hidden'>
              <table className=' min-w-full text-left text-sm font-light'>
                <thead className=' font-medium border-b-[0.5px] '>
                  <tr className='bg-[#D9D9D9]'>
                    {header?.map((item) => (
                      <th
                        scope='col'
                        className='py-2 font-semibold text-xl text-[#001935]'
                        key={item.key}
                      >
                        {item.title}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {column?.map((row, i) => (
                    <tr
                      className='transition duration-300 ease-in-out hover:bg-neutral-100 border-b-[0.5px] border-[#8d8989] border-opacity-80'
                      key={i}
                    >
                      {row?.map((item, index) => (
                        <td
                          className='whitespace-nowrap py-3 pr-4 text-[#001935] text-lg font-normal'
                          key={index}
                        >
                          {item}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
