import React, { useRef, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';

export default function NewEditor({ contentEditor, setContentEditor, initialValue, setInitialValue }) {

  // const [value, setValue] = useState(' <p> The quick brown fox jumps over the lazy dog </p> ');
  
  // const handleEditorChange = (content, editor) => {
      //setInitialValue(newValue)
      //setContentEditor(editor.getContent({format: 'text'}))
  //   setContentEditor(content);
  // }

  useEffect(() => setContentEditor(initialValue ?? ''), [initialValue, setContentEditor]);

  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent({format:'text'}));
    }
  };
  
  return (
    <>
      <Editor
        // value={value}
        initialValue = {initialValue}
        value={contentEditor}
        apiKey='l1481trysvjnvav06env938vg9ezsfbfl7ihy7vnvml8627k'
        onInit={(evt, editor) => editorRef.current = editor}
        // Store the current selections string/value in a variable and strip it's tags
        // onInit={(evt, editor) => {
        //   setContentEditor(editor.getContent({format:'text'}))
        // }}

        // onEditorChange = {(newValue, editor) => {
        //   // setInitialValue(newValue)
        //   setContentEditor(editor.getContent({format: 'text'}))
        // }}

        // initialValue="<p>This is the initial content of the editor.</p>"
        // onEditorChange={handleEditorChange}
        onEditorChange={(newValue, editor) => setContentEditor(newValue)}
        init={{
          height: 500,
          // menubar: false,
          plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
            'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
          ],
          toolbar: 'undo redo | blocks | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' + 
            ' image ' +
            'removeformat | help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        }}
      />
      <button onClick={log}>Log editor content</button>
    </>
  );
}