import React, { useEffect, useState } from 'react';
import InputBox from '../../components/Inputs/InputBox';
import Button from '../../components/Button';
import { Form, Formik } from 'formik';
import {
  TalentScoreSchema
} from '../../schema/score.schema';
import SuccessfulEntryModal from '../../components/Modal/SuccessfulEntryModal';
import { useAddDevsSoftSkillResultMutation, useVerifySoftSkillEmailMutation } from '../../reactWrapper/Redux/apiSlice';
import { toast } from 'react-toastify';


const EnterResult = () => {
  const [showModal, setShowModal] = useState(false);
  const [addDevsSoftSkillResult, { isLoading, isSuccess}] = useAddDevsSoftSkillResultMutation()
  const[verifySoftSkillEmail, {data}] = useVerifySoftSkillEmailMutation({ fixedCacheKey: 'shared-update-post'}) 

  const initialTalentScore = {
    email: data?.email,
    communication: '',
    motivation: '',
    consistency: '',
    leadership: '',
    creativity: '',
    productivity: '',
    depth: '',
    velocity: '',
    alignment: '',
    self:''
  };


  const handleSubmit =  (values,  {resetForm}) => {
    const result = new FormData()
     result.append('email', values.email);
     result.append('communication', values.communication);
     result.append('motivation', values.motivation);
     result.append('consistency_reliability', values.consistency);
     result.append('leadership', values.leadership);
     result.append('creativity', values.creativity);
     result.append('productivity', values.productivity);
     result.append('depth_of_past_work', values.depth);
     result.append('learning_velocity', values.velocity);
     result.append('value_alignment', values.alignment);
     result.append('self_driven', values.self);


    addDevsSoftSkillResult(result);
    resetForm()
 
  };

  useEffect(()=>{
    if(isSuccess){
      toast.success('Interview Score Added')

      setTimeout(()=>{
        window.location.href = `${process.env.REACT_APP_BASE_ADMIN_URL_LIVE}/search-talent`;
      }, 3000)
      


    }})
  
  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <SuccessfulEntryModal open={showModal} closeModal={handleCloseModal} />
      <div>
        <div className='max-w-[684px] mx-auto py-16 px-24 bg-white mt-[75px]'>
          <h2 className='text-3xl font-semibold text-center text-[#001935] leading-8 mb-[52px]'>
            Enter Talent Score
          </h2>
          <Formik
            onSubmit={handleSubmit}
            validationSchema={TalentScoreSchema}
            enableReinitialize
            initialValues={initialTalentScore}
          >
            {({
              values,
              setFieldTouched,
              isValid,
              setFieldValue,
              errors,
              handleSubmit,
             
              ...formik
            }) => (
              <Form>
                <div className='flex flex-col lg:gap-2.5 mb-10 lg:mb-40 w-full'>
                  <InputBox
                    type={'email'}
                    placeholder={'Email'}
                    id={'email'}
                    name={'email'}
                    value= {values?.email}
                    isValid={values?.email && !errors?.email}
                    setFieldTouched={setFieldTouched}
                    handleSubmit={handleSubmit}
                    
                  /> 
                  <InputBox
                    placeholder={'Communication'}
                    id={'communication'}
                    name={'communication'}
                    value={values?.communication}
                    isValid={values?.communication && !errors?.communication}
                    setFieldTouched={setFieldTouched}
                    handleSubmit={handleSubmit}
                  />
                  <InputBox
                    type={'motivation'}
                    placeholder={'Motivation'}
                    id={'motivation'}
                    name={'motivation'}
                    value={values?.motivation}
                    isValid={values?.motivation && !errors?.motivation}
                    setFieldTouched={setFieldTouched}
                    handleSubmit={handleSubmit}
                  />
                  <InputBox
                    type={'consistency'}
                    placeholder={'Consistency'}
                    id={'consistency'}
                    name={'consistency'}
                    value={values?.consistency}
                    isValid={values?.consistency && !errors?.consistency}
                    setFieldTouched={setFieldTouched}
                    handleSubmit={handleSubmit}
                  />
                  <InputBox
                    type={'leadership'}
                    placeholder={'Leadership'}
                    id={'leadership'}
                    name={'leadership'}
                    value={values?.leadership}
                    isValid={values?.leadership && !errors?.leadership}
                    setFieldTouched={setFieldTouched}
                    handleSubmit={handleSubmit}
                  />
                  <InputBox
                    type={'creativity'}
                    placeholder={'Creativity'}
                    label={''}
                    className={''}
                    id={'creativity'}
                    name={'creativity'}
                    value={values?.creativity}
                    isValid={values?.creativity && !errors?.creativity}
                    setFieldTouched={setFieldTouched}
                    handleSubmit={handleSubmit}
                  />
                  <InputBox
                    type={'productivity'}
                    placeholder={'Productivity'}
                    id={'productivity'}
                    name={'productivity'}
                    value={values?.productivity}
                    isValid={values?.productivity && !errors?.productivity}
                    setFieldTouched={setFieldTouched}
                    handleSubmit={handleSubmit}
                  />
                  <InputBox
                    type={'depth'}
                    placeholder={'Depth of past week'}
                    label={''}
                    className={''}
                    id={'depth'}
                    name={'depth'}
                    value={values?.depth}
                    isValid={values?.depth && !errors?.depth}
                    setFieldTouched={setFieldTouched}
                    handleSubmit={handleSubmit}
                  />
                  <InputBox
                    type={'velocity'}
                    placeholder={'Learning velocity'}
                    label={''}
                    className={''}
                    id={'velocity'}
                    name={'velocity'}
                    value={values?.velocity}
                    isValid={values?.velocity && !errors?.velocity}
                    setFieldTouched={setFieldTouched}
                    handleSubmit={handleSubmit}
                  />
                  <InputBox
                    type={'text'}
                    placeholder={'Value alignment'}
                    label={''}
                    className={''}
                    id={'alignment'}
                    name={'alignment'}
                    value={values?.alignment}
                    isValid={values?.alignment && !errors?.alignment}
                    setFieldTouched={setFieldTouched}
                    handleSubmit={handleSubmit}
                  />
                  <InputBox
                    type={'text'}
                    placeholder={'Self driven'}
                    label={''}
                    className={''}
                    id={'self'}
                    name={'self'}
                    value={values?.self}
                    isValid={values?.self && !errors?.self}
                    setFieldTouched={setFieldTouched}
                    handleSubmit={handleSubmit}
                  />
                  {/* <InputBox
                    type={'text'}
                    placeholder={'Interview video link'}
                    label={''}
                    className={''}
                    id={'link'}
                    name={'link'}
                    value={values?.link}
                    isValid={values?.link && !errors?.link}
                    setFieldTouched={setFieldTouched}
                    handleSubmit={handleSubmit}
                  /> */}
                </div>
                <div className='flex justify-between md:justify-end gap-6'>
                  <Button className={'bg-primary'} type='submit'  >
                    {
                    isLoading ? 'Submitting...' : 'Add Result'          
                  }
                  </Button>
                </div>
                </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default EnterResult;
