import React from 'react';
import PageIntro from '../../components/PageIntro/PageIntro';
import TalentRequestTable from '../../components/Table/TalentRequestTable';



const ClientRequest = () => {
    // const [page, setPage] = useState(1)
    
  return (
    <div>
      <div>
        <PageIntro
          title={'All Requests'}
          description={
            'Lorem ipsum dolor sit amet consectetur. Pharetra varius tristique mauris auctor mauris pellentesque odio accumsan eget. Nec at ut malesuada elit elementum non nunc. Tellus vel purus consectetur elit tempus molestie '
          }
        />
        <div className='mt-11'>
         <TalentRequestTable/>
          <div>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientRequest;
