import React, { useMemo, useState,useEffect,useCallback } from 'react';
import Table from './Table';
//import { TalentRequestsList } from '../../mockData';
import { useGetAllHireRequestQuery } from '../../reactWrapper/Redux/apiSlice';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Pagination from '../Pagination/Pagination';


const TalentRequestTable = () => {
  const siblingCount = 1;
  const pageColumn = 1;
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const {data} =useGetAllHireRequestQuery(page)
  

  const handlePageChange = useCallback(
    (page) => {
      setPage(page - 1);
    },
    [page]
  );



  const tableHeader = useMemo(
    () => [
      {
        title: 'Company',
        key: 'company',
      },
      {
        title: 'Stack',
        key: 'stack',
      },
      {
        title: 'Date',
        key: 'date',
      },
      {
        title: 'Location',
        key: 'Title',
      },
      {
        title: '',
        key: 'view matched talent',
      },
      {
        title: '',
        key: 'details',
      },
    ],
    []
  );

  // [
  // ],

  const modifiedData  = useMemo(()=>{
    return data?.data?.map((each)=>[

    <div className='flex gap-[18px] items-center'>
      <div className='w-11 h-11'>
        <img
          src={each?.companyLogo}
          alt='logo'
        />
      </div>
      <p>{each?.companyName}</p>
    </div>,
    each.stack,
    new Date(each?.postedDate).toLocaleDateString(),
    each.companyLocation,
    <Link
      className='text-sm inline-block rounded-lg border border-[#8D8989] py-[1.56px] px-[11.7px] text-[#575555] '
      to={`client-details/${each?.id}`}
    >
      Details
    </Link>,
     <Link
     className='text-sm inline-block rounded-lg border border-[#8D8989] py-[1.56px] px-[11.7px] text-[#575555] '
     to={`matched-talents/${each?.id}`}
   >
     Matched Talents
   </Link>,

    ])
  })

  useEffect(() => {
    setTotalCount(data?.total ?? 0);
  }, [data]);

  return (
    <div>
      <Table header={tableHeader} label= "" column={modifiedData} />

      <section className="w-full py-5">
        <Pagination
          rangeStart={
            totalCount !== 0
              ? (page || 0) * ((pageSize || 0) * pageColumn) + 1
              : 0
          }
          totalCount={totalCount}
          currentPage={(page || 0) + 1}
          pageSize={pageSize}
          pageColumn={pageColumn}
          siblingCount={siblingCount}
          onPageChange={handlePageChange}
          className="pt-9"
        />
        
        
      </section>

    </div>
  );
};

export default TalentRequestTable;
