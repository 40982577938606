import { UsersIcon } from '@heroicons/react/solid';
import React from 'react';
import { Language } from '../../components/Cbt/Language';
import { Skills } from '../../components/Cbt/Skills';
import { useGetACbtDetailQuery } from '../../reactWrapper/Redux/apiSlice';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { SoftSkill } from '../../components/Cbt/SoftSkill';

const CbtResult = () => {
  const {id} = useParams()
  const {data, isLoading} = useGetACbtDetailQuery(id)
  const results = Array.isArray(data?.userCbtResult) ? data?.userCbtResult:[]
  const [first] = results
  const hrResult = data?.userCbtSoftSkill ? data?.userCbtSoftSkill:[]
  

  if(isLoading){
    return <div>Loading...</div>
  }

  return (
   <div className='mt-0'>
      
      <div className='grid grid-cols-6'>
      <div className=" relative col-span-1 mt-0 relative bg-white rounded-lg shadow-md h-[200px] w-[200px] p-4">
          <h2 className="text-l font-bold mb-2 text-gray-400">Total Score</h2>
          <span className="absolute top-3 inline-block w-7 h-7 p-1 rounded-full bg-orange-200 right-3"><UsersIcon className="h-5 w-5 text-white" /></span>
          <p className="text-center p-6 text-7xl font-bold text-black-400">{first?.total_score}</p>
        </div>

        <div className=" relative col-span-1 mt-0 ml-8 relative bg-white rounded-lg shadow-md h-[200px] w-[200px] p-4">
          <h2 className="text-l font-bold mb-2 text-gray-400">Total Questions</h2>
          <span className="absolute top-3 inline-block w-7 h-7 p-1 rounded-full bg-orange-200 right-3"><UsersIcon className="h-5 w-5 text-white" /></span>
          <p className="text-center p-6 text-7xl font-bold text-black-400">{first?.total_number_of_question}</p>
        </div>
      </div>
   
     <div className='grid grid-cols-3 gap-2'>
       <Language lang = {first}/>
            
       <Skills skill = {first}/>

    </div>
    <SoftSkill hr = {hrResult}/>

  </div>
  );
};

export default CbtResult;
