import React from 'react';

const Badge = ({ label }) => {
  return (
    <div className='relative inline-flex  px-4  justify-center items-center rounded-[14px] border-[1.4px] border-[#8D8989] '>
      <span className='text-sm  py-1'>{label || 'Lorem ipsum'}</span>
    </div>
  );
};

export default Badge;
