import React from 'react';

const ProgressDetails = () => {
  return (
    <div>
      <h2 className='text-xl lg:text-2xl font-semibold leading-8 mb-3.5'>
        Progress Details
      </h2>
      <div className='gap-[30px] grid'>
        <div className='flex items-center gap-9'>
          <h3 className='text-xl font-semibold'>Talent Response:</h3>
          <p className='lg:text-lg text-[#575555]'>17/40</p>
        </div>
        <div className='flex items-center gap-9'>
          <h3 className='text-xl font-semibold'>Interview Invitation:</h3>
          <p className='lg:text-lg text-[#575555]'>Sent</p>
        </div>
        <div className='flex items-center gap-9'>
          <h3 className='text-xl font-semibold'>Gig Status</h3>
          <p className='lg:text-lg text-[#575555]'>Started</p>
        </div>
      </div>
    </div>
  );
};

export default ProgressDetails;
