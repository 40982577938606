import { MdLocationOn } from 'react-icons/md';

const ClientDetails = ({clientsdetails}) => {
  return (
    <section id='client-details'>
      <div>
        <h2 className='text-xl lg:text-2xl font-semibold leading-8 mb-4 text-[#001935]'>
          Client's details
        </h2>
        <div className='flex flex-col items-start lg:flex-row lg:flex-wrap  gap-10 lg:gap-20'>
          <div className='flex gap-[18px] items-center'>
            <div className='w-11 h-11'>
              <img
                alt='avatar'
                src='https://tecdn.b-cdn.net/img/new/avatars/2.webp'
              />
            </div>
            <span className={`text-[#001935] lg:text-lg`}>{clientsdetails?.companyName}</span>
          </div>
          <div>
            <div className='flex gap-3 items-center'>
              <h3 className='text-xl font-semibold'>Location</h3>
              <MdLocationOn width={14} height={20} color='#E27D03' />
            </div>
            <p className='lg:text-lg'>{clientsdetails?.companyLocation}</p>
          </div>
          <div className='flex flex-col gap-3.5 justify-center'>
            <div className='flex items-center gap-9'>
              <h3 className='text-xl font-semibold'>Phone:</h3>
              <p className='lg:text-lg text-[#575555]'>{clientsdetails?.phone}</p>
            </div>
            <div className='flex items-center gap-9'>
              <h3 className='text-xl font-semibold'>Email:</h3>
              <p className='lg:text-lg text-[#575555]'>
                {clientsdetails?.email}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ClientDetails;
