import React from 'react';

const Button = ({ children, className, onClick, type }) => {
  const onClickHandler = (e) => {
    if (typeof onClick === 'function') {
      e.preventDefault();
      onClick();
    }
  };
  return (
    <div>
      <button
        className={`${className} text-sm rounded-lg px-[30px] py-3.5 border border-transparent whitespace-nowrap bg-[#001935] text-white`}
        onClick={onClickHandler}
        type={type}
      >
        {children}
      </button>
    </div>
  );
};

export default Button;
