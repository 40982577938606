import React from 'react';

const Divider = ({ className }) => {
  return (
    <div>
      <hr
        className={`${
          className && className
        } h-[1px] border-t-0 bg-[#8D8989] opacity-100`}
      />
    </div>
  );
};

export default Divider;
