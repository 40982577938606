import React from 'react';
import EditPage from '../components/FirstSection/editPostForm';

const EditBlog = ({ match }) => {
  const { postId } = match.params

  return (
    <div>
        <EditPage postId={postId} />
    </div>
  )
}

export default EditBlog;