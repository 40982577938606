import { ErrorMessage, Field } from 'formik';
import React from 'react';
import { FiSearch } from 'react-icons/fi';

const IconInput = ({
  label,
  type,
  name,
  id,
  placeholder,
  value,
  setFieldTouched,
  handleSubmit,
}) => {
  return (
    <div className='inline-flex flex-col'>
      <label
        htmlFor={id}
        className='mb-[3px] inline-block  dark:text-neutral-200  text-[#575555]'
      >
        {label || ''}
      </label>
      <div className='inline-flex items-center gap-3 lg:gap-32 border border-input rounded-md px-[13px] py-3 text-[#575555] bg-white'>
        <Field
          className=' text-[#575555] outline-0'
          type={type}
          id={id}
          name={name}
          placeholder={placeholder}
          onKeyUp={(e) => {
            setFieldTouched(name, true);
            e.key === 'Enter' && handleSubmit();
          }}
        />
        <FiSearch size={18} />
      </div>
      <ErrorMessage
        name={name}
        render={(msg) => (
          <div className='text-[12px] text-red-600 text-left flex gap-1 mt-2 font-normal leading-none'>
            {msg}
          </div>
        )}
      />
    </div>
  );
};

export default IconInput;
