import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  profileData:null,
  companyData:null,
  projectManagerData:null,
  developersData:null,
  projectData:null,
  projectBiddersData:null

}

export const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    hydrateProfile: (state,action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based on those changes
      state.profileData = action.payload
    },
    hydrateCompanyData: (state,action) => {
      state.companyData = action.payload
    },

    hydrateProjectManagerData: (state,action) => {
      state.projectManagerData = action.payload
    },

    hydrateDevelopersData: (state,action) => {
      state.developersData = action.payload
    },
    hydratProjectData: (state,action) => {
      state.projectData = action.payload
    },
    //
    hydrateUserPersonalDetail:(state,action)=>{
      state.profileData[0].firstName = action.payload.firstname
      state.profileData[0].lastName = action.payload.lastname
      state.profileData[0].phone = action.payload.phone
      state.profileData[0].password = action.payload.password
      state.profileData[0].userDetail.language = action.payload.language
      state.profileData[0].userDetail.country = action.payload.country
    },
 //projectBiddersData
    hydratProjectBiddersData: (state,action) => {
    state.projectBiddersData = action.payload
    },
    
    hydrateCompanyGig:(state,action)=>{
      // work on this to update the particuler gig it
      //action.payload.gig_id
      state.companyData.data[0].companyGigs = action.payload
      // let theProject = state.companyData.data[0].companyGigs.find(single => single.uuid === uuid)
      
      
    },
  
  },
})

export const { hydrateProfile,hydrateCompanyData,hydrateProjectManagerData,hydrateDevelopersData, hydratProjectData,hydratProjectBiddersData,hydrateCompanyGig} = userProfileSlice.actions

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const hydrateProfileData = (userData) => (dispatch) => {
  setTimeout(() => {
    dispatch(hydrateProfile(userData))
  }, 1000)
}

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectProfile = (state) => state.profile.profileData
export const selectCompany = (state) => state.profile.companyData
export const selectProjectManager = (state) => state.profile.projectManagerData
export const selectDevelopers = (state) => state.profile.developersData
export const selectProjects = (state) => state.profile.projectData
export const selectProjectBidders = (state) => state.profile.projectBiddersData
// 




export default userProfileSlice.reducer