import { BrowserRouter, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Blog } from './Pages/Blog';
import { SingleBlog } from './Pages/BlogDetails';
import CbtResult from './Pages/CBT/cbt result';
import HomeAdminDashboard from './Pages/Dashboard';
import EditBlog from './Pages/EditPost';
import EnterResult from './Pages/HREnterResult/EnterResult';
import SearchTalent from './Pages/HREnterResult/SearchTalent';
import MyPost from './Pages/MyPost';
import CompanyDetails from './Pages/Project/CompanyDetails';
import SelectCatAut from './Pages/SelectCatAut';
import ClientRequest from './Pages/Talent/ClientRequest';
import MatchedTalents from './Pages/Talent/MatchedTalents';
import TalentPayment from './Pages/Talent/TalentPayment';
import { Header } from './components/Layouts/Header';
import { SideBar } from './components/Layouts/Sidebar';
import useHandleLogout from './reactWrapper/hooks/useHandleLogout';
import { useUserProfileMutation } from './reactWrapper/endpoints/auth/auth.api';
import { addUserProfile, selectUserState } from './reactWrapper/Redux/auth/authSlice';
import { TOKEN } from './helper/constant';
import { Fragment, useEffect } from 'react';
import PageLoader from './components/Loader/PageLoader';
import {
  useAppDispatch,
  useTypedSelector,
} from "./reactWrapper/Redux/utilities";


// eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOjUsImlhdCI6MTcxOTE5Nzk1N30.Q3109j7z-2kpUXLEutZ_-IJjf2rLV7hVFBDxJy0m-CA


function App() {
  const userState = useTypedSelector(selectUserState);
  const dispatch = useAppDispatch();
  const { getProfile } = useUserProfileMutation();
  const { handleLogout } = useHandleLogout();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has("enter")) {
      const formData = new FormData();
      const token = params.get("enter")?.trim() ?? "";
      formData.append("userToken", token);
      getProfile(formData, {
        onSuccess: (res) => {
          const userInfo = {
            accessToken: res.accessToken,
            user: {
              ...res.user.data[0],
              extraDetail: res.user.data[1],
            },
          };
          dispatch(addUserProfile(userInfo));
        },
        onError: (error) => {
          // console.log("error", error);
        },
      });
      localStorage.setItem(TOKEN, token ?? "");
    } else if (localStorage.getItem(TOKEN)) {
      if (userState.accessToken) return;

      const formData = new FormData();
      formData.append("userToken", localStorage.getItem(TOKEN) ?? "");
      getProfile(formData, {
        onSuccess: (res) => {
          const userInfo = {
            accessToken: res.accessToken,
            user: {
              ...res.user.data[0],
              extraDetail: res.user.data[1],
            },
          };
          dispatch(addUserProfile(userInfo));
        },
        onError: (error) => {
          // console.log("error", error);
        },
      });
    } else {
      handleLogout();
    }
  }, []);

  if (userState.accessToken === null || userState?.user?.role !== "admin")
    return (
      <Fragment>
        <PageLoader />
      </Fragment>
    );

  
  return (
    <>
      <div className='flex flex-row min-h-screen bg-gray-100 text-gray-800'>
        <BrowserRouter>
          <ToastContainer />
          <SideBar />
          <Header>
            <div className='mt-6 mx-3'>
          
              <Route path='/postblog' exact component={Blog} />
              <Route path='/mypost' exact component={MyPost} />
              <Route
                path='/createauthorcategory'
                exact
                component={SelectCatAut}
              />
            
              <Route path='/mypost/:postId' exact component={SingleBlog} />
              <Route path='/editpost/:postId' exact component={EditBlog} />
              <Route path='/search-talent' exact component={SearchTalent} />
              <Route
                path='/search-talent/result'
                exact
                component={EnterResult}
              />
              <Route path='/client-request/' exact component={ClientRequest} />
              <Route
                path='/matched-talents/:id'
                exact
                component={MatchedTalents}
              />
              <Route path='/payments' exact component={TalentPayment} />
              <Route path='/client-details/:id' exact component={CompanyDetails} />
              <Route path='/matched-talents/cbt-profile/:id' exact component={CbtResult} />

              <Route path='/' exact component={HomeAdminDashboard} />
            </div>
          </Header>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
