import React from 'react';
import Badge from '../Badge/Badge';

const SkillsInfo = ({skills}) => {
  return (
    <div>
      <div className='gap-[30px] grid  md:grid-cols-3 grid-cols-2 '>
        <div>
          <h3 className='font-semibold leading-8 lg:text-xl  mb-3'>Role</h3>
          <p></p>
        </div>
        <div>
          <h3 className='font-semibold leading-8 lg:text-xl  mb-3'>Stack</h3>
          <Badge /> {skills?.stack}
        </div>
        <div>
          <h3 className='font-semibold leading-8 lg:text-xl  mb-3'>Skill</h3>
          {/* <Badge /> */}{skills?.skill2}
        </div>
        <div>
          <h3 className='font-semibold leading-8 lg:text-xl mb-3'>Framework</h3>
          {skills?.framework1}
        </div>
        <div>
          <h3 className='font-semibold leading-8 lg:text-xl mb-3'>Language</h3>
          {/* <Badge /> */}{skills?.language1}
        </div>
      </div>
    </div>
  );
};

export default SkillsInfo;
