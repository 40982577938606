import axios from "axios";
import { TOKEN } from "../../helper/constant";

let baseURL = process.env.REACT_APP_BASE_API_URL + "/api/v1";

const headers = {};

const axiosInstance = axios.create({
  baseURL,
  timeout: 20000,
  headers,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(TOKEN) ?? "";

    if (token && config.headers) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
