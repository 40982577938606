import React from 'react';
import { Link } from 'react-router-dom';
import Status from '../components/Status/Status';

export const MatchedTalentsList = [
  [
    <div className='flex gap-[18px] items-center'>
      <div className='w-11 h-11'>
        <img
          src='https://tecdn.b-cdn.net/img/new/avatars/2.webp'
          alt='profile'
        />
      </div>
      <p>{'Lopez Lawrence'}</p>
    </div>,
    'Node JS Developer',
    'Feb 19th 2023',
    <Status label='Available' />,
    <Link
      className='text-sm inline-block rounded-lg border border-[#8D8989] py-[1.56px] px-[11.7px] text-[#575555] '
      to='/dashboard/talent-details/:1'
    >
      Details
    </Link>,
  ],
  [
    <div className='flex gap-[18px] items-center'>
      <div className='w-11 h-11'>
        <img
          src='https://tecdn.b-cdn.net/img/new/avatars/2.webp'
          alt='profile'
        />
      </div>
      <p>{'Lopez Lawrence'}</p>
    </div>,
    'Node JS Developer',
    'Feb 19th 2023',
    <Status label='Pending' />,
    <Link
      className='text-sm inline-block rounded-lg py-[1.56px] px-[11.7px] text-[#575555] border border-[#8D8989] '
      to='/dashboard/talent-details/:1'
    >
      Details
    </Link>,
  ],
];

export const TalentRequestsList = [
  [
    <div className='flex gap-[18px] items-center'>
      <div className='w-11 h-11'>
        <img
          src='https://tecdn.b-cdn.net/img/new/avatars/2.webp'
          alt='profile'
        />
      </div>
      <p>{'Lopez Lawrence'}</p>
    </div>,
    'Node JS Developer',
    'Feb 19, 2023',
    '5',
    <Link
      className='text-sm inline-block rounded-md py-[1.56px] px-[11.7px] text-[#575555] border border-[#8D8989]'
      to='/matched-talents/'
    >
      Matched Talents
    </Link>,
    <Link
      className='text-sm inline-block rounded-md py-[1.56px] px-[11.7px] text-[#575555] border border-[#8D8989]'
      to='/client-details/:1'
    >
      Details
    </Link>
  ],
  [
    <div className='flex gap-[18px] items-center'>
      <div className='w-11 h-11'>
        <img
          src='https://tecdn.b-cdn.net/img/new/avatars/2.webp'
          alt='profile'
        />
      </div>
      <p>{'Lopez Lawrence'}</p>
    </div>,
    'Node JS Developer',
    'Feb 19, 2023',
    '5',
    <Link
      className='text-sm inline-block rounded-md py-[1.56px] px-[11.7px] text-[#575555] border border-[#8D8989]'
      to='/matched-talents/'
    >
      Matched Talents
    </Link>,
    <Link
      className='text-sm inline-block rounded-md py-[1.56px] px-[11.7px]  text-[#575555] border border-[#8D8989]'
      to='/client-details/:1'
    >
      Details
    </Link>,
  ],
  [
    <div className='flex gap-[18px] items-center'>
      <div className='w-11 h-11'>
        <img
          src='https://tecdn.b-cdn.net/img/new/avatars/2.webp'
          alt='profile'
        />
      </div>
      <p>{'Lopez Lawrence'}</p>
    </div>,
    'Node JS Developer',
    'Feb 19, 2023',
    '5',
    <Link
      className='text-sm inline-block rounded-md py-[1.56px] px-[11.7px] text-[#575555] border border-[#8D8989]'
      to='/matched-talents/'
    >
      Matched Talents
    </Link>,
    <Link
      className='text-sm inline-block rounded-md py-[1.56px] px-[11.7px]  text-[#575555] border border-[#8D8989] '
      to='/client-details/:1'
    >
      Details
    </Link>,
  ],
];

export const ProjectList = [
  [
    <div className='flex gap-[18px] items-center'>
      <div className='w-11 h-11'>
        <img
          src='https://tecdn.b-cdn.net/img/new/avatars/2.webp'
          alt='profile'
        />
      </div>
      <p>{'Lopez Lawrence'}</p>
    </div>,
    'Node JS Developer',
    'Feb 19, 2023',
    '5',
    <Link
      className='text-sm inline-block rounded-md py-[1.56px] px-[11.7px] text-[#575555] border border-[#8D8989]'
      to='/projects/:1'
    >
      Details
    </Link>,
  ],
  [
    <div className='flex gap-[18px] items-center'>
      <div className='w-11 h-11'>
        <img
          src='https://tecdn.b-cdn.net/img/new/avatars/2.webp'
          alt='profile'
        />
      </div>
      <p>{'Lopez Lawrence'}</p>
    </div>,
    'Node JS Developer',
    'Feb 19, 2023',
    '5',
    <Link
      className='text-sm inline-block rounded-md py-[1.56px] px-[11.7px]  text-[#575555] border border-[#8D8989]'
      to='/projects/:1'
    >
      Details
    </Link>,
  ],
  [
    <div className='flex gap-[18px] items-center'>
      <div className='w-11 h-11'>
        <img
          src='https://tecdn.b-cdn.net/img/new/avatars/2.webp'
          alt='profile'
        />
      </div>
      <p>{'Lopez Lawrence'}</p>
    </div>,
    'Node JS Developer',
    'Feb 19, 2023',
    '5',
    <Link
      className='text-sm inline-block rounded-md py-[1.56px] px-[11.7px]  text-[#575555] border border-[#8D8989] '
      to='/projects/:1'
    >
      Details
    </Link>,
  ],
];

export const PaymentRequestsList = [
  [
    <div className='flex gap-[18px] items-center'>
      <p>{'Lopez Lawrence'}</p>
    </div>,
    '123456',
    '1234567890',
    'Feb 19, 2023',
    '5,000',
    <Link
      className='text-sm inline-block rounded-md py-[1.56px] px-[11.7px]  text-[#575555] border border-[#8D8989] '
      to='/dashboard/talent-details/:1'
    >
      Details
    </Link>,
  ],
  [
    <div className='flex gap-[18px] items-center'>
      <p>{'Lopez Lawrence'}</p>
    </div>,
    '123456',
    '1234567890',
    'Feb 19, 2023',
    '5,000',
    <Link
      className='text-sm inline-block rounded-md py-[1.56px] px-[11.7px]  text-[#575555] border border-[#8D8989]'
      to='/dashboard/talent-details/:1'
    >
      Details
    </Link>,
  ],
  [
    <div className='flex gap-[18px] items-center'>
      <p>{'Lopez Lawrence'}</p>
    </div>,
    '123456',
    '1234567890',
    'Feb 19, 2023',
    '5,000',
    <Link
      className='text-sm inline-block rounded-md py-[1.56px] px-[11.7px]  text-[#575555] border border-[#8D8989]'
      to='/dashboard/talent-details/:1'
    >
      Details
    </Link>,
  ],
];
