import { createSlice } from "@reduxjs/toolkit";
import { AuthState } from "./auth.type";
import { RootState } from "../utilities";

const initialState: AuthState = {
  user: null,
  token: null,
  accessToken: null,
  isAuthenticated: false,
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: () => {
      return initialState;
    },
    addUserProfile: (state, { payload }) => {
      state.accessToken = payload.accessToken;
      state.isAuthenticated = true;
      state.user = payload.user;
    },
  },
});

export const { logout, addUserProfile } = slice.actions;
export default slice.reducer;

export const selectUserState: (state: RootState) => AuthState = (
  state: RootState
) => state.auth;
